<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <b-card title="Change Password">
        <validation-observer ref="simpleRules">
          <b-form
            class="mt-2"
            @submit.prevent="changePassword"
          >
            <b-row>
              <b-col
                sm="12"
                class="mb-1"
              >
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="New Password"
                >
                  <validation-provider
                    #default="{ errors }"
                    ref="password"
                    name="Password"
                    vid="Password"
                    rules="required|min:6"
                    mode="lazy"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="reset-password-new"
                        v-model="password"
                        :type="password1FieldType"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="password-new"
                        placeholder=""
                        value=""
                        autocomplete="new-password"
                        required
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password1ToggleIcon"
                          @click="togglePassword1Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="12" />
              <b-col sm="12">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="4"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Confirm Password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    rules="required|min:6|confirmed:Password"
                    mode="lazy"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="reset-password-confirm"
                        v-model="cPassword"
                        :type="password2FieldType"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false:null"
                        name="password-confirm"
                        placeholder=""
                        autocomplete="new-password"
                        required
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password2ToggleIcon"
                          @click="togglePassword2Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-card-footer class="no-padding mt-2">
              <b-row>
                <b-col
                  cols="12"
                  class="d-flex justify-content-end"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-2"
                    :disabled="isSubmitting"
                    type="submit"
                  >
                    Set new password
                  </b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BCardFooter,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AccountService from '@/services/AccountService'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { handleValidationError } from '@/utils/helpers'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardFooter,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isSubmitting: false,
      password: null,
      cPassword: '',
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    async changePassword() {
      this.$refs.simpleRules.validate()
        .then(async success => {
          if (success) {
            this.isSubmitting = true
            const data = {
              general: {
                password: this.password,
              },
            }
            try {
              await AccountService.updateAccountSettings(data)
                .then(response => {
                  store.dispatch('auth/setAuthUser', response.data.data)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Success',
                      text: 'The password has been changed',
                      icon: 'EditIcon',
                      variant: 'success',
                    },
                  })
                }).catch(error => {
                  handleValidationError(error, this)
                })
            } catch (error) {
              // API-related errors will be shown by the API error handler
              console.error(error)
            } finally {
              this.isSubmitting = false
            }
          }
        })
    },
  },

}
</script>
<style scoped>
.no-padding{
  padding:0;
}
</style>
